import * as React from "react";
import "./s-index.css";

function Landing() {
  return (
    <div className="landing">
      <div className="landing-wrap">
        <div className="landing-content">
          <h1>Charlie Harding is a freelance product designer who weaves together the functional and the beautiful.</h1>
          <div className="status">
            <div>
            <h3>Available for work in London or Remote — <a href="mailto:me@charlieharding.co.nz">Contact&nbsp;me</a></h3>
            <h4>Product design<span></span>Visual design<span></span>UX / UI</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;