import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import './s-index.css';
import './s-case.css';
import Navigation from "./p-nav.js";
import Footer from "./p-footer.js";
import Portfolio from "./p-work-nav.js";
import Landing from "./p-landing.js";
import About from "./p-about.js";
import GE from './cs-GE';
import GA from './cs-GA';
import SS from './cs-SS';
import RF from './cs-RF';
import AML from './cs-AM';

export function HomeBuild(){
  // Function to add the transitioning class when the home button is clicked
  // Set the delay on the the home button delay link in nav.js 
  let overlay = document.getElementById('root')
  let top = document.getElementById('top-h1')
  top.className = 'top-load-message'
  let timer1 = setTimeout(
    () => overlay.className = 'home-start'
    ,2000
  );
  let timer2 = setTimeout(
    () => overlay.className = 'home-active'
    ,2500
  );
  let timer3 = setTimeout(
    () => overlay.className = 'home-done'
    ,4000
  );
}

export default function HomeTransition(){
  // Function to add the transitioning class when the home button is clicked
  // Set the delay on the the home button delay link in nav.js 
  let overlay = document.getElementById('root')
  overlay.className = 'home-transition';
  // let activeTimer = setTimeout(
  //   () => overlay.className = 'home-active'
  //   ,800
  // );
  let doneTimer = setTimeout(
    () => overlay.className = 'home-done'
    ,2000
  );
}



class Loader extends React.Component {
  render(){
    return (
      <div id="loadOverlay">
        <div className="loading-message">
          <h1>This is the portfolio of Charlie&nbsp;Harding</h1>
          <h1 id="top-h1">This is the portfolio of Charlie&nbsp;Harding</h1>
        </div>
      </div>
    );
  }
}

function checkPage(locale){
  // Expand this function for more than home or case-study page types
  if(locale === "/General-Electric" || locale === "/Aston-Martin" || locale === "/Secret-Speyside" || locale === "/Gambleaware" || locale === "/Refuge"  ){
    return 'case-study';
  }else if(locale === "/About"){
    return 'about';
  }
  return 'home';
}

class Site extends React.Component {

  componentDidMount(){
    // Runs first time
    HomeBuild()
  };

  render(){
    return (
       <Router>
        <Route render={({location}) => (
          <div id="holder" className={checkPage(location.pathname)}>
            <Loader />
            <Navigation page={location.pathname}/>
            {/* Passes the page location down to the porfolio list so
                it's positioned to the  correct case study header */}
            <Landing />
            <Portfolio page={location.pathname}/>
            <TransitionGroup>
              <CSSTransition key={location.key} timeout={1000} classNames='fade'>
                <Switch location={location}>
                  <Route exact path="/">
                    <div>
                    </div>
                  </Route>
                  <Route path="/About">
                    <About />
                  </Route>
                  <Route path="/Aston-Martin">
                    <div className="case-study-content">
                      <AML />
                      <Footer />
                    </div>
                  </Route>
                  <Route path="/General-Electric">
                    <div className="case-study-content">
                      <GE />
                      <Footer />
                    </div>
                  </Route>
                  <Route path="/Secret-Speyside">
                    <div className="case-study-content">
                      <SS />
                      <Footer />
                    </div>
                  </Route>
                  <Route path="/Gambleaware">
                    <div className="case-study-content">
                      <GA />
                      <Footer />
                    </div>
                  </Route>
                  <Route path="/Refuge">
                    <div className="case-study-content">
                      <RF />
                      <Footer />
                    </div>
                  </Route>
                  <Route path='/Charlie-Harding_Portfolio.key' component={() => { 
                      window.location.href = 'https://charlieharding.co.nz/Charlie-Harding_Portfolio.key'; 
                      return null;
                  }}/>
                  <Route path='/Charlie-harding_CV.pdf' component={() => { 
                      window.location.href = 'https://charlieharding.co.nz/Charlie-harding_CV.pdf'; 
                      return null;
                  }}/>
                  <Route path="*"></Route>
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </div>
        )} />
      </Router>
    );
  }
}

ReactDOM.render(
  <Site />,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
