import React from 'react';
import asset2 from "./assets/rf/RF-02.png"
import asset3 from "./assets/rf/RF-03.png"
// import asset4 from "./assets/rf/RF-04.png"
import asset8 from "./assets/rf/RF-08.png"
import asset9 from "./assets/rf/RF-09.png"
import asset10 from "./assets/rf/RF-10.png"

function RF(){
  return (
    <div className="case-page RF">
      <RFDetails />
      <RFContent />
    </div>
  )
}

function RFDetails(){
  return (
    <div className="case-details width-14">
      <div className="meta-data width-3 col-1 gutter-right">
        <div>
          <h4>Client</h4>
          <p>Women's Refuge</p>
        </div>
        <div>
          <h4>Year</h4>
          <p>2020—2021</p>
        </div>
        <div>
          <h4>Role</h4>
          <p>Lead Designer</p>
        </div>
      </div>
      <div className="project-desc width-9">
        <p>In 2020 Women’s Refuge took exclusive control of the National Domestic Violence Helpline and together we worked to redesign the site to better serve as a triage point for women in need. A major challenge was balancing the many user needs some of which were very high stakes—literally life and death in some cases.</p>
        <p>Part of the brief was to refresh the Women’s refuge brand which was focussed on an audience of potential donors and the general public. It worked well for shocking statistics on a billboard but was far too harsh when used content aimed at helping vulnerable women.</p>
        <p>Accessibility was a huge focus on this project, domestic violence effects women from all backgrounds and the site needs to cater to everyone. Right-to-left languages, British Sign Language, poor vision, and dyslexia are just some of the considerations we designed for.</p>
    </div>
  </div>
  )
}


function RFContent(){
  return (
    <div className="case-contents width-14">
      <div className="asset-right">
        <img className="width-4 gutter-right" alt="" src={asset2} />
        <img className="width-4" alt="" src={asset3} />
      </div>
      <div className="">
        <img className="width-9" alt="" src={asset10} />
      </div>
      <div className="">
        <img className="width-3 gutter-right" alt="" src={asset9} />
        <img className="width-11" alt="" src={asset8} />
      </div>
    </div>
  )
}


export default RF;

