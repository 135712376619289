import React from 'react';
import asset1 from "./assets/ga/GA-01.png"
import asset2 from "./assets/ga/GA-02.png"
import asset4 from "./assets/ga/GA-04.png"
import asset5 from "./assets/ga/GA-05.png"
import asset6 from "./assets/ga/GA-06.png"
import asset7 from "./assets/ga/GA-07.png"
import asset8 from "./assets/ga/GA-08.png"
import asset9 from "./assets/ga/GA-09.png"
import asset11 from "./assets/ga/GA-11.png"
import asset12 from "./assets/ga/GA-12.png"


function GA(){
  return (
    <div className="case-page GA">
      <GADetails />
      <GAContent />
    </div>
  )
}

function GADetails(){
  return (
    <div className="case-details width-14">
      <div className="meta-data width-3 col-1 gutter-right">
        <div>
          <h4>Client</h4>
          <p>Gambleaware</p>
        </div>
        <div>
          <h4>Year</h4>
          <p>2020</p>
        </div>
        <div>
          <h4>Role</h4>
          <p>Senior Designer</p>
        </div>
      </div>
      <div className="project-desc width-9">
        <p>This project brief was to combine the Gambleaware professional resources and the general audience content of Begambleaware.org together under one site while improving the UX and UI.</p>
        <p>The client had received a lot of feedback around the ‘clinical’ feeling of their original site so it was a priority that the content was presented in a friendlier and more approachable way, while not completely abandoning the existing brand or losing credibility. We ended up expanding the colour palette and introducing textural elements that brought an easy-going and human quality to the site.</p>
      </div>
    </div>
  )
}


function GAContent(){
  return (
    <div className="case-contents width-14">
      <div className="asset-right">
        <img className="width-4 gutter-right" alt="" src={asset1} />
        <img className="width-4" alt="" src={asset2} />
      </div>
      <div className="asset-left">
        <img className="width-12" alt="" src={asset6} />
      </div>
      <div className="">
        <img className="width-4 gutter-right" alt="" src={asset4} />
        <img className="width-4" alt="" src={asset5} />
      </div>
      <div className="asset-right">
        <img className="width-10" alt="" src={asset7} />
      </div>
      <div className="asset-right">
        <img className="width-4 gutter-right" alt="" src={asset8} />
        <img className="width-4 gutter-right" alt="" src={asset9} />
        <img className="width-4" alt="" src={asset11} />
      </div>
      <div className="">
        <img className="width-12" alt="" src={asset12} />
      </div>
    </div>
  )
}


export default GA;

