import * as React from "react";
import "./s-index.css";
import amlogo from "./assets/logos/aston-logo.svg"
import fblogo from "./assets/logos/facebook-logo.svg"
import gelogo from "./assets/logos/ge-logo.svg"
import glenlogo from "./assets/logos/glenlivet-logo.svg"
import hsbclogo from "./assets/logos/hsbc-logo.svg"
import nzlogo from "./assets/logos/nz-logo.svg"
import pernodlogo from "./assets/logos/pernod-logo.svg"
import refugelogo from "./assets/logos/refuge-logo.svg"
import specslogo from "./assets/logos/specs-logo.svg"
import speylogo from "./assets/logos/speyside-logo.svg"
import wtlogo from "./assets/logos/wt-logo.svg"
import zlogo from "./assets/logos/z-logo.svg"
import pawslogo from "./assets/logos/paws-logo.svg"
import gamblelogo from "./assets/logos/Begambleaware-logo.svg"

function About() {
  return (
    <div className="about-content">
      <div className="bio">
        <p>Hi there.</p>
        <p>I'm a freelance senior product designer working in London and remotely.</p>
        <p>Originally from New Zealand I have 8 years of design experience—the last 5 spent working in agencies and in-house in the United Kingdom.</p>
        <p>I thrive in the space between research and tech, taking data and insights and turning them into functional and beautiful digital products ready for development. If you have a project that fits the bill please get in touch.</p>
        <div>
          <a href="mailto:me@charlieharding.co.nz">Email</a>
          <a href="https://www.linkedin.com/in/charlie-harding-designer/">LinkedIn</a>
        </div>
      </div>
      <div className="client-list">
        <h3>Clients I've worked with</h3>
        <div className="logo-block">
          <img src={amlogo} alt="Aston Martin Logo" />
          <img src={gelogo} alt="General Electric Logo" />
          <img src={pawslogo} alt="Paws Logo" />
          <img src={refugelogo} alt="Women's Refuge Logo" />
          <img src={speylogo} alt="Secret Speyside Logo" />
          <img src={gamblelogo} alt="Begambleaware Logo" />
          <img src={fblogo} alt="Facebook Logo" />
          <img src={wtlogo} alt="Wunderman Thompson Logo" />
          <img src={hsbclogo} alt="HSBC Logo" />
          <img src={glenlogo} alt="The Glenlevit Logo" />
          <img src={pernodlogo} alt="Pernod Ricard Logo" />
          <img src={specslogo} alt="Specsavers Logo" />
          <img src={nzlogo} alt="New Zealand Government Logo" />
          <img src={zlogo} alt="Z Energy Logo" />
        </div>
      </div>
      <div className="downloads">
        <h3>Downloads</h3>
        <div className="download-holder">
          <a className='width-4' target="_blank" href="./Charlie-harding_CV.pdf">
            <h4>Résumé</h4>
            <p><span>PDF</span> 1.7 mb</p>
          </a>
          <a className='width-4' target="_blank" href="./Charlie-Harding_Portfolio.key">
            <h4>Portfolio</h4>
            <p><span>Keynote</span> 247 mb</p>
          </a>
        </div>
      </div>
      <div className="table">
        <h3>Employment History</h3>
        <table>
          <tbody>
            <tr>
              <th scope="row">Paws Group</th>
              <td>Senior designer</td>
              <td>Jul 2021 — Feb 2022</td>
            </tr>
            <tr>
              <th scope="row">Wunderman Thompson</th>
              <td>Senior designer</td>
              <td>Dec 2020 — Jul 2021</td>
            </tr>
            <tr>
              <th scope="row">Mirum</th>
              <td>Senior designer</td>
              <td>Oct 2019 — Dec 2020</td>
            </tr>
            <tr>
              <th rowSpan="2">Possible</th>
              <td>Senior designer</td>
              <td>Mar 2019 — Oct 2019</td>
            </tr>
            <tr className="sub">
              <td>Designer</td>
              <td>Sep 2017 — Mar 2019</td>
            </tr>
            <tr>
              <th scope="row">Heyday</th>
              <td>Designer</td>
              <td>Feb 2015 — Jun 2017</td>
            </tr>
            <tr>
              <th scope="row">Immersion edutainment</th>
              <td>Designer</td>
              <td>Dec 2013 — Nov 2014</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="table">
        <h3>Education</h3>
        <table>
          <tbody>
            <tr>
              <th scope="row">Massey University</th>
              <td>Bachelor of Design with Honours, 1st Class</td>
              <td>2010 – 2013</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="about-footer">
        <div>
          <p className="bold">Charlie Harding Limited</p>
          <p>63-66 Hatton Garden, Suite 23</p>
          <p>EC1N 8LE, London</p>
          <p>Company Number 13798210</p>
        </div>
        <div>
          <p className="align-right">&#169; Charlie Harding Limited  — 2022</p>
        </div>
      </div>
    </div>
  );
}

export default About;