import React from 'react';
import asset1 from "./assets/ge/GE-01.png"
import asset2 from "./assets/ge/GE-02.png"
import asset3 from "./assets/ge/GE-03.png"
import asset4 from "./assets/ge/GE-04.png"
import asset5 from "./assets/ge/GE-05.png"
import asset6 from "./assets/ge/GE-06.png"
import asset7 from "./assets/ge/GE-07.png"
import asset8 from "./assets/ge/GE-08.png"
import asset9 from "./assets/ge/GE-reports.mp4"
import asset9poster from "./assets/ge/GE-reports-poster.png"

function GE(){
  return (
    <div className="case-page GE">
      <Details />
      <GeContent />
    </div>
  )
}

function Details(){
  return (
    <div className="case-details width-14">
      <div className="meta-data width-3 col-1 gutter-right">
         <div>
          <h4>Agency</h4>
          <p>Wunderman Thompson</p>
        </div>
        <div>
          <h4>Year</h4>
          <p>2020</p>
        </div>
        <div>
          <h4>Role</h4>
          <p>Senior Designer</p>
        </div>
      </div>
      <div className="project-desc width-9">
        <p>General Electric is billion dollar, 128 year old, truly global company founded by Thomas Edison himself. They are leaders in several industries, some of which they helped create.</p>
        <p>Their brief for us was a redesign of their global website with the goal of re-positioning GE as the multi-industry titan that they are. We focused on elevating their thought-leadership content and creating a site structure that worked for GE's sub-brands such as Aviation and Healthcare. At the same time we built out a design system that could be used to bring cohesion across GE's network.</p>
        <p>It was important that the look and feel positioned GE as current and technologically savvy. We achieved this with a brand refresh that incorporated well considered typography, deeper and more vibrant colours, arresting imagery and layered information. The client loved these brand treatments enough that they've been incorporated into all GE outputs in both digital and traditional channels.</p>
      </div>
    </div>
  )
}

function GeContent(){
  return (
    <div className="case-contents width-14">
      <div className="asset-right">
        <img className="width-4 gutter-right" alt="" src={asset1} />
        <img className="width-4" alt="" src={asset2} />
      </div>
      <div className="vid-left">
        <video className="width-12" poster={asset9poster} muted loop playsInline autoPlay>
          <source src={asset9} alt="" type="video/mp4" />
        </video>
      </div>
      <div className="mobile-left">
        <img className="width-3 gutter-right" alt="" src={asset3} />
        <img className="width-3 gutter-right col-1" alt="" src={asset4} />
        <img className="width-3 gutter-right col-1" alt="" src={asset5} />
      </div>
      <div className="asset-right">
        <img className="width-10" alt="" src={asset6} />
      </div>
      <div className="">
        <img className="width-11 gutter-right" alt="" src={asset8} />
        <img className="width-3" alt="" src={asset7} />
      </div>
    </div>
  )
}


export default GE;
