import React from 'react';
import Spey01 from "./assets/ss/SS-01.mp4"
import Spey02 from "./assets/ss/SS-02.mp4"
import Spey03 from "./assets/ss/SS-03.mp4"
import Spey05 from "./assets/ss/SS-05.png"
import Spey06 from "./assets/ss/SS-06.png"
import Spey07 from "./assets/ss/SS-07.png"

function SS(){
  return (
    <div className="case-page SS">
      <SSDetails />
      <SSContent />
    </div>
  )
}

function SSDetails(){
  return (
    <div className="case-details width-14">
      <div className="meta-data width-3 col-1 gutter-right">
        <div>
          <h4>Agency / Client</h4>
          <p>POSSIBLE / Pernod Ricard</p>
        </div>
        <div>
          <h4>Year</h4>
          <p>2019</p>
        </div>
        <div>
          <h4>Role</h4>
          <p>Senior Designer</p>
        </div>
      </div>
      <div className="project-desc width-9">
        <p>In 2019 Pernod Ricard launched a new range of high end whiskies collectively named ‘Secret Speyside’ that brought together single malts from four Scottish distilleries. The brief was to build the online touchpoint that introduced these whiskies and told their story in more depth.</p>
        <p>The website needed to build the unique mythos around each whisky so it was important that the experience, appearance and content all worked together to reinforce the luxury of the product. We wanted the seamless navigation, elegant design and immersive video to transport the reader into a world of misty Scottish hillsides and luxurious swirling glasses.</p>
        <p>This project was great for me as I was involved from the pitch through to the final build and combined with the leanest of the team it meant I had a lot influence over the creative output.</p>
      </div>
    </div>
  )
}


function SSContent(){
  return (
    <div className="case-contents width-14">
      <div className="asset-right">
        <video className="width-5" poster="" muted loop playsInline autoPlay>
          <source src={Spey02} alt="" type="video/mp4" />
          </video>
      </div>
      <div className="vid-left">
        <video className="width-12" poster="" muted loop playsInline autoPlay>
          <source src={Spey01} alt="" type="video/mp4" />
          </video>
      </div>
      <div className="asset-right">
        <img className="width-4 gutter-right" alt="" src={Spey05} />
        <img className="width-4 gutter-right" alt="" src={Spey06} />
        <img className="width-4" alt="" src={Spey07} />
        {/* <p className="caption width-3">Redesigning the General Electric corporate website and re-invigorating their digital design language.</p> */}
      </div>
      <div className="vid-left">
        <video className="width-12" poster="" muted loop playsInline autoPlay>
          <source src={Spey03} alt="" type="video/mp4" />
          </video>
      </div>
    </div>
  )
}


export default SS;

