import React from 'react';
import DelayLink from 'react-delay-link';
import HomeTransition from './index';
import { NavLink } from "react-router-dom";

function Transition(){
    console.log('about')
}

class Footer extends React.Component {

  constructor(props) {
    super(props);
  }

  render(){
      return ( 
        <footer>
          <div className="site-links col-1 width-2">
            <DelayLink delay={500} to="/" clickAction={HomeTransition} replace={false}><a>Home</a></DelayLink>
            <DelayLink delay={500} to="/About" clickAction={Transition} replace={false}><a>About</a></DelayLink>
          </div>
          <div className="case-links width-3">
            <DelayLink delay={500} to="/Aston-Martin" clickAction={Transition} replace={false}><a>Aston Martin</a></DelayLink>
            <DelayLink delay={500} to="/General-Electric" clickAction={Transition} replace={false}><a>General Electric</a></DelayLink>
            <DelayLink delay={500} to="/Secret-Speyside" clickAction={Transition} replace={false}><a>Secret Speyside</a></DelayLink>
            <DelayLink delay={500} to="/Refuge" clickAction={Transition} replace={false}><a>Refuge</a></DelayLink>
            <DelayLink delay={500} to="/Gambleaware" clickAction={Transition} replace={false}><a>Gambleaware</a></DelayLink>
          </div>
          <div className="out-links col-1 width-3">
            <a href="mailto:me@charlieharding.co.nz">Email</a>
            <a href="https://www.linkedin.com/in/charlie-harding-designer/">LinkedIn</a>
          </div>
          <div className="bus-details width-4">
            <p><span className="bold">Charlie Harding Limited</span></p>
            <p>63-66 Hatton Garden, Suite 23</p>
            <p>EC1N 8LE, London</p>
            <p>Company Number 13798210</p>
            <p><span className="half-text">&#169; Charlie Harding Limited — 2022</span></p>
          </div>

        </footer>
      );
    }
}

export default Footer;

