import React from 'react';
// import AMvisual from "./assets/aml/AM-01-vid.mp4"
// import AMposter from "./assets/aml/AM-01-poster.jpg"
import asset1 from "./assets/aml/AM-07.png"
import asset2 from "./assets/aml/AM-08.png"
import asset3 from "./assets/aml/AM-02-vid.mp4"
import asset3poster from "./assets/aml/AM-02-poster.png"
import asset4 from "./assets/aml/AM-03.jpg"
import asset5 from "./assets/aml/AM-04.jpg"
import asset6 from "./assets/aml/AM-05.jpg"
import asset7 from "./assets/aml/AM-06.jpg"

function AML(){
  return (
    <div className="case-page AM">
      {/* <CaseHeader /> */}
      <CaseDetails />
      <CaseBody />
    </div>
  )
}

// function CaseHeader(){
//   return (
//     <div className="case-header">
//     <img className="bg-wrapper" src={AMposter} alt=""></img>
//     <video className='carousel-visual carousel-video' poster={AMposter} muted loop playsInline>
//         <source src={AMvisual} type='video/mp4' />
//     </video>
//     </div>
//   )
// }


function CaseDetails(){
  return (
    <div className="case-details width-14">
      <div className="meta-data width-3 col-1 gutter-right">
        <div>
          <h4>Agency</h4>
          <p>POSSIBLE</p>
        </div>
        <div>
          <h4>Year</h4>
          <p>2019—2020</p>
        </div>
        <div>
          <h4>Role</h4>
          <p>Senior Designer</p>
        </div>
      </div>
      <div className="project-desc width-9">
        <p>Aston Martin is one of the best known brands in the world, responsible for producing beautiful luxury cars that are British to the core. I’ve had the opportunity to work with them across a number of projects that have developed and expanded their digital eco-system.</p>
        <p>Some notable projects I’ve had chance to work on include updating the site navigation, creating the ‘Art of living’ section to house their experience offering, and most recently a new look and feel that developed their digital brand. The new look and feel gave us the chance to introduce a new modern UI and was rolled out in a refreshed home and model pages.</p>
      </div>
    </div>
  )
}


function CaseBody(){
  return (
    <div className="case-contents width-14">
      <div className="asset-right">
        <img className="width-4 gutter-right" alt="" src={asset1} />
        <img className="width-4" alt="" src={asset2} />
      </div>
      <div className="vid-left">
        <video className="width-12" poster={asset3poster} muted loop playsInline autoPlay>
          <source src={asset3} alt="" type="video/mp4" />
        </video>
      </div>
      <div className="mobile-left">
        <img className="width-3 gutter-right" alt="" src={asset4} />
        <img className="width-3 gutter-right col-1" alt="" src={asset5} />
        <img className="width-3 gutter-right col-1" alt="" src={asset6} />
      </div>
      <div className="asset-right">
        <img className="width-10" alt="" src={asset7} />
      </div>
    </div>
  )
}


export default AML;
