import React from 'react';
import { 
  Link,
  NavLink
 } from "react-router-dom";

// import StartVideo from './index';
import GEvisual from "./assets/ge/GE-main.mp4"
import GEposter from "./assets/ge/GE-main-poster.png"
import GEbg from "./assets/ge/GE-ul-bg.png"
import GEmobile from "./assets/ge/GE-01.png"
import SSvisual from "./assets/ss/SS-main.mp4"
import SSposter from "./assets/ss/SSposter.jpg"
import SSmobile from "./assets/ss/SS-04.png"
import SSbg from "./assets/ss/SS-ul-bg.png"
import AMvisual from "./assets/aml/AM-01-vid.mp4"
import AMposter from "./assets/aml/AM-01-poster.jpg"
import AMmobile from "./assets/aml/AM-07.png"
import AMbg from "./assets/aml/AM-ul-bg.png"
import RFvideo from "./assets/rf/RF-00.mp4"
import RFposter from "./assets/rf/RF-poster.png"
import RFbg from "./assets/rf/RF-ul-bg.png"
import RFmobile from "./assets/rf/RF-02.png"
import GAvisual from "./assets/ga/GA-00.png"
import GAbg from "./assets/ga/GA-ul-bg.png"
import GAmobile from "./assets/ga/GA-01.png"

// Create case study headers
const gambleaware = {
  key: "gakey", 
  to: "/Gambleaware",
  name: "Gambleaware",
  id: "gaLink",
  firstName: "Gamble",
  lastName: "aware",
  visualtype: "img",
  onScreen: false,
  visual: GAvisual,
  background: GAbg,
  mobile: GAmobile,
  desc: "An approachable and user-friendly experience for those needing help with gambling.",
  linkhref: "https://www.begambleaware.org/",
  linktext: "Begambleaware.org"
}

const general = {
  key: "gekey", 
  to: "/General-Electric",
  name: "General Electric",
  firstName: "General",
  id: "geLink",
  lastName: "Electric",
  visualtype: "vid",
  onScreen: false,
  visual: GEvisual,
  poster: GEposter,
  background: GEbg,
  mobile: GEmobile,
  desc: "Redesigning the General Electric corporate website and re-invigorating their digital design language.",
  linkhref: "https://www.ge.com/",
  linktext: "General Electric"
}

const aston = {
  key: "amkey", 
  to: "/Aston-Martin",
  name: "Aston Martin",
  id: "amLink",
  firstName: "Aston",
  lastName: "Martin",
  visualtype: "vid",
  onScreen: true,
  visual: AMvisual,
  poster: AMposter,
  background: AMbg,
  mobile: AMmobile,
  desc: "Long term ownership of the British luxury car brand’s digital presence.",
  linkhref: "https://www.astonmartin.com/en-gb/",
  linktext: "Aston Martin"
}

const refuge = {
  key: "rfkey", 
  to: "/Refuge",
  name: "Women's Refuge",
  id: "reLink",
  firstName: "Women's",
  lastName: "Refuge",
  visualtype: "vid",
  onScreen: false,
  visual: RFvideo,
  poster: RFposter,
  background: RFbg,
  mobile: RFmobile,
  desc: "Refreshing the National domestic helpline and Refuge brand with a focus on accessibility.",
  linkhref: "https://www.nationaldahelpline.org.uk/",
  linktext: "National Helpline"
}

const speyside = {
  key: "sskey", 
  to: "/Secret-Speyside",
  name: "Secret Speyside",
  id: "ssLink",
  firstName: "Secret",
  lastName: "Speyside",
  visualtype: "vid",
  onScreen: false,
  visual: SSvisual,
  poster: SSposter,
  background: SSbg,
  mobile: SSmobile,
  desc: "Creating the launch platform for a new range of unique high end whiskies.",
  linkhref: "https://www.secret-speyside.com/en-en/",
  linktext: "Secret Speyside"
}

function CreateVisual(props) {
  // Function to create either an image or a video for the main carousel visual
  if(props.source.visualtype === "vid"){
    return (
      <video className='carousel-visual carousel-video desktop-hero' poster={props.source.poster} muted loop playsInline>
        <source src={props.source.visual} type='video/mp4' />
      </video>
    );
  }else{
   return <img className='carousel-visual desktop-hero' src={props.source.visual} />
  }
}

function StartVideo(){
  //Function to play the carousel video when on case study page
  const videos = document.getElementsByClassName('carousel-video');
  for(var i = 0; i < videos.length; i++) {
    if(videos[i].parentNode.className === 'active'){
      videos[i].play();
    }else{
      // Stop the other carousel videos
      // Using load to return to the poster image state
      videos[i].load();
    }
  }
}

// Add case study headers to array
const workarray = [aston, general, speyside, refuge, gambleaware]

class Portfolio extends React.Component {
  
  constructor(props) {
    super(props);
    //Functions need to be bound to this so that they can access the other bound variables.
    this.portScroll = this.portScroll.bind(this);
    this.portScrollMobile = this.portScrollMobile.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setHeader = this.setHeader.bind(this);
    this.scrolling = false;
    this.scrollTime = {};
  }

  setHeader(){
    // Sets the portfolio ul to the correct positon on case study page loads.
    let location = this.props.page;
    workarray.forEach(function(element) {
      // Find the case study in the work array that matches the page location
      if(location === element.to){
        let move = document.getElementById(element.id);
        // Scoll the portfolio UL to that position, instantly
        move.scrollIntoView({block: "center"});
      }
    })
  }

  setBgImage(){
    // Set the portfolio carousel background image to match the main image
    let scrollDis = document.getElementById("portfolio-list").scrollTop;
    let height = document.getElementById("portfolio-list").offsetHeight;
    let num = Math.round( scrollDis / height )
    for(let i = 0; i < workarray.length; i++){
      if(i === num){
        workarray[i].onScreen = true;
      }else{
        workarray[i].onScreen = false;
      }
    }
    // Trigger a re-render
    this.setState({});
  }

  setBgImageMobile(){
    // Set the portfolio carousel background image to match the main image
    let scrollDis = document.getElementById("port-wrap").scrollLeft;
    let width = document.getElementById("port-wrap").offsetWidth;
    let num = Math.round( scrollDis / width )
    for(let i = 0; i < workarray.length; i++){
      if(i === num){
        workarray[i].onScreen = true;
      }else{
        workarray[i].onScreen = false;
      }
    }
    // Trigger a re-render
    this.setState({});
  }
  
  portScroll(){
    // Runs when the portfolio UL is scrolled
    // Start by clearing the timer so that it only fires once the scroll event is finished
    clearTimeout(this.scrollTime);
    this.scrollTime = setTimeout(() => {
      this.setBgImage();
    }, 100);
  }

  portScrollMobile(){
    // Runs when the portfolio UL is scrolled on mobile screens
    // Start by clearing the timer so that it only fires once the scroll event is finished
    clearTimeout(this.scrollTime);
    this.scrollTime = setTimeout(() => {
      this.setBgImageMobile();
    }, 100);
  }

  handleClick(id){
    let check = window.matchMedia('(max-width: 680px)');
    // On clicking a portfolio item scroll the ul parent contatiner so the block is centered
    if(check.matches){
      // Runs on Mobile
      window.scroll({
        top: 0,
        behavior: 'instant'
      });
      let move = document.getElementById(id);
      move.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
    }else{
      let move = document.getElementById(id);
      move.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
    }
  }

  componentDidMount(){
    // Runs first time
    this.setHeader();
    StartVideo();
  };

  componentDidUpdate(){
    // Runs every time other than first mount
    StartVideo();
  }

  render(){
    return ( 
      <div className="work-nav" id="work-carousel">
        <div id="pagination">
          {workarray.map((props) =>
            <button className={props.onScreen ? "active-ui" : ""} key={props.key + '-ui'} onClick={() => this.handleClick(props.id)}>
            </button>
          )
          }
        </div>
        <ul id="portfolio-background">
          {workarray.map((props) =>
            <li key={props.key + 'bg-li'}>
              <img className={props.onScreen ? "bg-wrapper active-bg" : "bg-wrapper"} key={props.key + "bg"} src={props.background} alt=""></img>
            </li>
          )
          }
        </ul>
        <div id="port-wrap" onScroll={this.portScrollMobile}>
          <ul id="portfolio-list" onScroll={this.portScroll}>
            {workarray.map((props) =>
              <li key={props.key}>
                <NavLink id={props.id} onClick={() => this.handleClick(props.id)} to={props.to}>
                  <div className="headerDetails">
                    <h2>{props.firstName}<br />{props.lastName}</h2>
                  </div>
                  <CreateVisual source={props} />
                  <img className='carousel-visual mobile-hero' src={props.mobile} />
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default Portfolio;




