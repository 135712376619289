import React from 'react';
import DelayLink from 'react-delay-link';
import HomeTransition from './index';
import { NavLink } from "react-router-dom";

function skip(){

}


function AboutTransition(){
  // Scroll the to the top of the page to refresh for about.
  let moveTimer = setTimeout(
    () => window.scroll({top: 0, behavior: 'instant'})
    ,1200
  );
}

class Navigation extends React.Component {

  constructor(props) {
    super(props);
  }

  render(){
      return ( 
        <div className="nav">
          <div className="top-nav">
            {/* Use a delay link to add a transition from the case study back to home */}
            <DelayLink delay={this.props.page === '/About' ? 0 : 500} to="/" clickAction={this.props.page === '/About' ? skip : HomeTransition } replace={false}><a className="homelink">Home</a></DelayLink>
            <DelayLink delay={0} to="/About" clickAction={AboutTransition} replace={false}><a className="aboutlink">About</a></DelayLink>
          </div>
        </div>
      );
    }
}

export default Navigation;

